import {
  postBodyRequest, exportRequest
} from '@/utils/axios'

export function add (params) {
  return postBodyRequest('/trans/data/vehicle/quota/add', params)
}
export function page (params) {
  return postBodyRequest('/trans/data/vehicle/quota/list', params)
}
export function quotaexport (params, fileName) {
  return exportRequest('/trans/data/vehicle/quota/export', params, fileName)
}
export function transferQuotaexport (params, fileName) {
  return exportRequest('/trans/data/vehicle/quota/transfer/export', params, fileName)
}

export function deliveryExport (params, fileName) {
  return exportRequest('/trans/data/vehicle/quota/delivery/export', params, fileName)
}

export function imgSumExport (params, fileName) {
  return exportRequest('/trans/data/vehicle/quota/imgSum/export', params, fileName)
}

export function inspectionExport (params, fileName) {
  return exportRequest('/trans/data/vehicle/quota/inspection/export', params, fileName)
}

export function receivingExport (params, fileName) {
  return exportRequest('/trans/data/vehicle/quota/receiving/export', params, fileName)
}

export function stockExport (params, fileName) {
  return exportRequest('/trans/data/vehicle/quota/stock/export', params, fileName)
}

export function freightExport (params, fileName) {
  return exportRequest('/trans/data/vehicle/quota/freight/export', params, fileName)
}

export function trackExport (params, fileName) {
  return exportRequest('/trans/data/vehicle/quota/track/export', params, fileName)
}

export function appletExport (params, fileName) {
  return exportRequest('/trans/data/vehicle/quota/applet/export', params, fileName)
}

export function estimatedProfit (params, fileName) {
  return exportRequest('/trans/data/vehicle/quota/estimated-profit/export', params, fileName)
}

export function estimatedProfitPage (params, fileName) {
  return postBodyRequest('/trans/data/vehicle/quota/estimated-profit/page', params, fileName)
}
