<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-form layout="inline" :model="searchForm">
        <a-form-item label="">
          <a-range-picker v-model:value="quotaTime" valueFormat="YYYY-MM-DD" placeholder="选择时间" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.profitStart" type="number" placeholder="利润起" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.profitEnd" type="number" placeholder="利润止" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.orderId" placeholder="订单号" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.vinNo" placeholder="车架号" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.customerName" placeholder="客户名称" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-tree-select show-search style="width: 200px" :treeDefaultExpandAll="false" placeholder="机构"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" allow-clear tree-default-expand-all
            @change="chooseOrg">
            <a-tree-select-node v-for="item in orgList" :key="item.id" :value="item.name + ',' + item.id"
              :title="item.name">
              <a-tree-select-node v-for="v in item.treeList" :key="v.id" :value="v.name + ',' + v.id" :title="v.name">
              </a-tree-select-node>
            </a-tree-select-node>
          </a-tree-select>
        </a-form-item>
        <a-space>
          <a-button class="searchLoading-button" :loading="loading" type="primary" @click="loadData" :disabled="loading">
            查询
          </a-button>
          <a-button @click="reset">
            重置
          </a-button>
        </a-space>
      </a-form>
    </template>
    <a-button @click="exportData" danger ghost :loading="loading">
      预计利润导出
    </a-button>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" :loading="loading"
      :pagination="pagination" @change="handleTableChange">
    </a-table>
  </suy-table>
</template>
<script>

import {
  estimatedProfit, estimatedProfitPage
} from '@/api/dataCenter/quota'
import { toRefs, reactive, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { getOrgList } from '@/utils/util'
import SuyTable from '@/components/SuyTable'
export default {

  setup () {
    const state = reactive({
      formRef: null,
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        profitStart: undefined,
        profitEnd: undefined,
        vinNo: undefined,
        startTime: undefined,
        endTime: undefined,
        orgId: null,
      },
      mirrorSearchForm: {},
      listData: [],
      quotaTime: [],
      columns: [
        {
          title: '客户名',
          dataIndex: 'customerName'
        },
        {
          title: '起始地',
          dataIndex: 'startDetails'
        },
        {
          title: '目的地',
          dataIndex: 'endDetails'
        },
        {
          title: '合同号',
          dataIndex: 'id'
        },
        {
          title: '车架号',
          dataIndex: 'vinNo'
        },
        {
          title: '业务员',
          dataIndex: 'salesman'
        },
        {
          title: '分单员',
          dataIndex: 'sourceMan'
        },
        {
          title: '部门',
          dataIndex: 'orgName'
        },
        {
          title: '预计利润',
          dataIndex: 'estGrossProfit'
        },
        {
          title: '实际利润',
          dataIndex: 'grossProfit'
        },
        {
          title: '结算方式',
          dataIndex: 'orderSettlement.label'
        },
        {
          title: '结算状态',
          dataIndex: 'settlementStatus.label'
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus.label'
        },
        {
          title: '录单时间',
          dataIndex: 'createTime'
        },
        {
          title: '推送时间',
          dataIndex: 'pushTime'
        },
        {
          title: '是否提车',
          dataIndex: 'isPlaceIn.label'
        },
        {
          title: '是否送车',
          dataIndex: 'isTakeOut.label'
        },
        {
          title: '运输阶段',
          dataIndex: 'transportType.label'
        },
        {
          title: '运输状态',
          dataIndex: 'transStatus.label'
        },
        {
          title: '当前城市',
          dataIndex: 'nowCity'
        }
      ],
      modalVisible: false,
      orgList: []
    })
    const exportData = () => {
      if (state.quotaTime.length === 0) {
        message.info('请选择时间')
        return
      }
      state.loading = true
      state.searchForm.startTime = state.quotaTime[0]
      state.searchForm.endTime = state.quotaTime[1]
      estimatedProfit(state.searchForm, '预计利润数据表').then(res => {
        state.loading = false
      }).finally(() => {
        state.loading = false
      })
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const loadData = () => {
      if (state.quotaTime.length === 0) {
        message.info('请选择时间')
        return
      }
      state.loading = true
      state.searchForm.startTime = state.quotaTime[0]
      state.searchForm.endTime = state.quotaTime[1]
      estimatedProfitPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => { console.log(err) })
        .finally(() => {
          state.loading = false
        })
    }
    // 重置
    const reset = () => {
      let time = {}
      time.startTime = state.searchForm.startTime
      time.endTime = state.searchForm.endTime
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.searchForm.startTime = time.startTime
      state.searchForm.endTime = time.endTime
      loadData()
    }
    const onMonth = () => {
      const nowDate = new Date()
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      }
      state.searchForm.quota = '' + date.year + '-' + date.month
    }
    const chooseOrg = (e, v) => {
      if (e) state.searchForm.orgId = e.split(',')[1]
      else state.searchForm.orgId = ''
    }
    onMounted(async () => {
      onMonth()
      state.orgList = await getOrgList()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })

    return {
      ...toRefs(state),
      loadData,
      chooseOrg,
      handleTableChange,
      reset,
      exportData
    }
  },
  components: {
    SuyTable
  }
}
</script>
